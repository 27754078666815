import React, { useEffect, useState } from 'react';
import { useUser } from '../../context/UserContext';
import { Box, Grid, Typography } from '@mui/material';
import { motion } from 'framer-motion';
import MainView from '../views/MainView';
import SelectedStream from './SelectedStream';

const StreamStatistics = ({ openDialogComponent = () => {}, setIsBlur = () => {}, backgroundColor = '#5D3B9E', userSettings = {}, update = () => {}, refresh = () => {} }) => {
    const authCtx = useUser();
    const apiUrl = process.env.REACT_APP_API_URL;
    const [streams, setStreams] = useState([]);
    const [selectedStream, setSelectedStream] = useState(null);
    const [loading, setLoading] = useState(true);

    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)},${parseInt(backgroundColor.slice(3, 5), 16)},${parseInt(backgroundColor.slice(5, 7), 16)},0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)},${parseInt(backgroundColor.slice(3, 5), 16)},${parseInt(backgroundColor.slice(5, 7), 16)},0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)},${parseInt(backgroundColor.slice(3, 5), 16)},${parseInt(backgroundColor.slice(5, 7), 16)},0.25)`
    };

    const getStreams = async () => {
        try {
            const response = await fetch(`${apiUrl}/user/getstreams?token=${authCtx.token}`);
            const res = await response.json();
            const sortedStreams = res.streams.sort((a, b) => {
                const dateA = new Date(a.replace(/T(\d{2})-(\d{2})-(\d{2})Z$/, 'T$1:$2:$3Z'));
                const dateB = new Date(b.replace(/T(\d{2})-(\d{2})-(\d{2})Z$/, 'T$1:$2:$3Z'));
                return dateB - dateA;
            });
            setStreams(sortedStreams);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    };

    const getStreamData = async (filename) => {
        try {
            const response = await fetch(`${apiUrl}/user/getstream?token=${authCtx.token}&filename=${filename}`);
            const res = await response.json();
            setSelectedStream({...res.data,
                combinedMetrics: prepareCombinedMetrics(res.data)
            });
        } catch (error) {
            console.log(`Error fetching stream data for ${filename}:`, error);
        }
    };

    const getThumbnailUrl = (filename) => {
        return `${apiUrl}/user/getstreamthumbnail?token=${authCtx.token}&filename=${filename}`;
    };

    const formatDate = (filename) => {
        const isoDate = filename.replace(/T(\d{2})-(\d{2})-(\d{2})Z$/, 'T$1:$2:$3Z');
        const date = new Date(isoDate);

        if (isNaN(date.getTime())) {
            throw new Error(`Invalid date in filename: ${filename}`);
        }

        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Intl.DateTimeFormat('en-GB', options).format(date);
    };

    const prepareCombinedMetrics = (data) => ({
        viewerCounts: data.viewerCounts || [],
        chatActivity: data.chatActivity || []
    });

    useEffect(() => {
        getStreams();
    }, []);

    return (
        <MainView>
            <Box sx={{padding: '16px', backgroundColor: colorScheme.background25, width: '100%', height: '100%'}}>
                {selectedStream ? (
                    <SelectedStream streamData={selectedStream} onBack={() => setSelectedStream(null)} colorScheme={colorScheme} />
                ) : loading ? (
                    <Typography variant="h6" align="center">Loading...</Typography>
                ) : (
                    <Grid container spacing={2}>
                        {streams.length > 0 ? (
                            <React.Fragment>
                                {streams.map((stream, index) => (
                                    <Grid item xs={12} sm={4} md={3} lg={2} key={index}>
                                        <motion.div whileHover={{ scale: 1.025 }} whileTap={{scale: 0.975}} onClick={() => getStreamData(stream)}>
                                            <Box sx={{backgroundColor, borderRadius: '8px', boxShadow: 2, overflow: 'hidden', textAlign: 'center', cursor: 'pointer'}}>
                                                <img src={getThumbnailUrl(stream)} alt={`Thumbnail for ${stream}`} style={{ width: '100%', height: 'auto' }} />
                                                <Typography variant="subtitle1" sx={{ padding: '8px', fontWeight: 'bold' }}>
                                                    {formatDate(stream)}
                                                </Typography>
                                            </Box>
                                        </motion.div>
                                    </Grid>
                                ))}
                            </React.Fragment>
                        ) : (
                            <React.Fragment>
                                <h2 style={{marginLeft: '32px'}}>No streams found</h2>
                            </React.Fragment>
                        )}
                    </Grid>
                )}
            </Box>
        </MainView>
    );
};

export default StreamStatistics;