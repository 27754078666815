import React, { useEffect, useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { Toolbar } from 'primereact/toolbar';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { Chart } from 'primereact/chart';
import { motion } from 'framer-motion';

// Utility functions
/**
 * Groups data into intervals and calculates aggregated values (sum or average) for each interval.
 *
 * @param {Array} data - The array of data to be grouped.
 * @param {number} intervalInSeconds - The interval duration in seconds.
 * @param {Function} getKey - A function to extract the timestamp from each data item.
 * @param {Function} [getValue=() => 1] - A function to extract the value from each data item (default: 1).
 * @param {'sum'|'average'} [aggregationMode='sum'] - The aggregation mode: 'sum' for total values or 'average' for average values.
 * @returns {Array} - An array of grouped data, each with a timestamp and a calculated count.
 */
const groupByInterval = (data, intervalInSeconds, getKey, getValue = () => 1, aggregationMode = 'sum') => {
    const grouped = [];
    const intervalInMs = intervalInSeconds * 1000;

    // Sort the data by timestamp to ensure proper grouping
    const sortedData = [...data].sort((a, b) => new Date(getKey(a)) - new Date(getKey(b)));

    let intervalStart = null;
    let accumulatedValue = 0;
    let count = 0;

    sortedData.forEach((item) => {
        const timestamp = new Date(getKey(item)).getTime();
        const value = getValue(item);

        // Start a new interval or move to the next one
        if (!intervalStart || timestamp >= intervalStart + intervalInMs) {
            if (intervalStart !== null) {
                grouped.push({
                    timestamp: new Date(intervalStart).toISOString(),
                    count: aggregationMode === 'average' && count > 0 ? accumulatedValue / count : accumulatedValue,
                });
            }
            intervalStart = Math.floor(timestamp / intervalInMs) * intervalInMs;
            accumulatedValue = value; // Reset for the new interval
            count = 1;
        } else {
            accumulatedValue += value;
            count++;
        }
    });

    // Push the final interval
    if (intervalStart !== null) {
        grouped.push({
            timestamp: new Date(intervalStart).toISOString(),
            count: aggregationMode === 'average' && count > 0 ? accumulatedValue / count : accumulatedValue,
        });
    }

    return grouped;
};

/**
 * Generates default chart options for a chart.js-based chart.
 *
 * @param {string} lineColor - The color to be used for text, ticks, and axes titles.
 * @param {string} yAxisTitle - The title for the Y-axis.
 * @param {number} maxY - The maximum suggested value for the Y-axis.
 * @returns {Object} - An object containing chart configuration options.
 */
const defaultChartOptions = (lineColor, yAxisTitle, maxY, xAxisTitle = 'Time') => ({
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
        legend: {
            display: true,
            position: 'top',
            labels: {
                color: lineColor
            }
        }
    },
    scales: {
        x: {
            ticks: {
                color: lineColor
            },
            title: {
                display: true,
                text: xAxisTitle,
                color: lineColor
            }
        },
        y: {
            ticks: {
                color: lineColor,
                stepSize: 1
            },
            beginAtZero: true,
            suggestedMax: maxY,
            title: {
                display: true,
                text: yAxisTitle,
                color: lineColor
            }
        }
    }
});

// Configuration for each stat type
const statConfig = {
    combinedMetrics: {
        label: 'Combined Metrics',
        chartTypes: ['line'],
        intervalEnabled: true,
        render: (data, lineColor, interval) => {
            if (!data.viewerCounts || !data.chatActivity) {
                return <Typography variant="body1">Insufficient data to render combined metrics.</Typography>;
            }
            // Group data for each metric
            const groupedViewerCounts = groupByInterval(data.viewerCounts, interval, (item) => item.timestamp, (item) => item.count, 'average');
            const groupedChatActivity = groupByInterval(data.chatActivity, interval, (item) => item.timestamp, () => 1, 'sum');
            // Generate a complete set of timestamps
            const allTimestamps = Array.from(new Set([
                    ...groupedViewerCounts.map((entry) => entry.timestamp), 
                    ...groupedChatActivity.map((entry) => entry.timestamp)
                ])).sort((a, b) => new Date(a) - new Date(b));
            // Fill missing timestamps with 0 counts
            const alignDataToTimestamps = (groupedData, allTimestamps) => (
                allTimestamps.map((timestamp) => {
                    const found = groupedData.find((entry) => entry.timestamp === timestamp);
                    return found ? found.count : 0;
                })
            );
            // Align all datasets to the complete set of timestamps
            const alignedViewerCounts = alignDataToTimestamps(groupedViewerCounts, allTimestamps);
            const alignedChatActivity = alignDataToTimestamps(groupedChatActivity, allTimestamps);
            // Find the maximum Y value across all datasets
            const maxY = Math.max(...alignedViewerCounts, ...alignedChatActivity) + 1;
            // Chart Data
            const combinedData = {
                labels: allTimestamps.map((timestamp) =>
                    new Date(timestamp).toLocaleTimeString('en-GB', {
                        hour: '2-digit',
                        minute: '2-digit'
                    })
                ),
                datasets: [
                    {
                        label: 'Viewer Counts',
                        data: alignedViewerCounts,
                        fill: false,
                        borderColor: 'rgba(75, 192, 192, 1)',
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        tension: 0.4
                    },
                    {
                        label: 'Chat Activity',
                        data: alignedChatActivity,
                        fill: false,
                        borderColor: 'rgba(255, 99, 132, 1)',
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        tension: 0.4
                    }
                ]
            };
            return (
                <Chart type="line" data={combinedData} options={defaultChartOptions(lineColor, 'Metrics Over Time', maxY)} style={{height: '50vh', width: '100%'}} />
            );
        },
    },
    viewerCounts: {
        label: 'Viewership',
        chartTypes: ['line'],
        intervalEnabled: true,
        render: (data, lineColor, interval, chartType) => {
            const groupedData = groupByInterval(data, interval, (item) => item.timestamp, (item) => item.count, 'average');
            const maxY = Math.max(...groupedData.map((entry) => entry.count)) + 1;
            const getViewerCountsData = () => {
                return {
                    labels: groupedData.map((entry) =>
                        new Date(entry.timestamp).toLocaleTimeString('en-GB', {
                            hour: '2-digit',
                            minute: '2-digit'
                        })
                    ),
                    datasets: [
                        {
                            label: 'Viewer Count Over Time',
                            data: groupedData.map((entry) => entry.count),
                            fill: true,
                            borderColor: lineColor,
                            tension: 0.4
                        }
                    ]
                };
            };
            return (
                <Chart type="line" data={getViewerCountsData()} options={defaultChartOptions(lineColor, 'Viewer Count', maxY)} style={{ height: '100%', width: '100%' }} />
            );
        },
    },
    clipCreations: {
        label: 'Clip Creations',
        chartTypes: ['grid'],
        intervalEnabled: false,
        render: (data, lineColor) => (
            <Grid container spacing={2}>
                {data.map((clip) => (
                    <Grid item xs={12} sm={4} md={3} lg={2} key={clip.id}>
                        <motion.div whileHover={{ scale: 1.025 }} whileTap={{ scale: 0.975 }} onClick={() => window.open(clip.url, '_blank')} style={{ cursor: 'pointer' }}>
                            <Box sx={{backgroundColor: 'rgba(0, 0, 0, 0.05)', borderRadius: '8px', boxShadow: 2, overflow: 'hidden', textAlign: 'center'}}>
                                <img src={clip.thumbnail_url} alt={clip.title} style={{ width: '100%', height: 'auto' }} />
                                <Typography variant="subtitle1" sx={{ padding: '8px', fontWeight: 'bold' }}>{clip.title}</Typography>
                            </Box>
                        </motion.div>
                    </Grid>
                ))}
            </Grid>
        ),
    },
    adBreaks: {
        label: 'Ad Breaks',
        chartTypes: ['bar'],
        intervalEnabled: false,
        render: (data, lineColor) => {
            const totalAdDuration = data.reduce((sum, ad) => sum + ad.length, 0);
            const averageAdDuration = (totalAdDuration / data.length).toFixed(2);
            const totalAdBreaks = data.length;
            const getAdBreaksData = () => {
                return {
                    labels: data.map((ad) =>
                        new Date(ad.timestamp).toLocaleTimeString('en-GB', {
                            hour: '2-digit',
                            minute: '2-digit'
                        })
                    ),
                    datasets: [
                        {
                            label: 'Ad Duration (seconds)',
                            data: data.map((ad) => ad.length),
                            backgroundColor: lineColor,
                            borderColor: lineColor,
                            borderWidth: 1
                        }
                    ]
                };
            };
            return (
                <Box>
                    <Box sx={{display: 'flex', justifyContent: 'flex-start', gap: '32px', marginBottom: '16px'}}>
                        <Typography>Total Ad Breaks: <strong>{totalAdBreaks}</strong></Typography>
                        <Typography>Total Ad Duration: <strong>{totalAdDuration} seconds</strong></Typography>
                        <Typography>Average Ad Duration: <strong>{averageAdDuration} seconds</strong></Typography>
                    </Box>
                    <Chart type="bar" data={getAdBreaksData()} style={{ height: '50vh', width: '100%' }}
                        options={defaultChartOptions(lineColor, 'Ad Duration (seconds)', Math.max(...data.map((ad) => ad.length)) + 10)} />
                </Box>
            );
        },
    },
    songRequests: {
        label: 'Song Requests',
        chartTypes: ['table'],
        intervalEnabled: false,
        render: (data, lineColor) => {
            const totalRequests = data.length;
            // Calculate song request counts
            const songCounts = data.reduce((counts, request) => {
                const key = `${request.songTitle} by ${request.artist}`;
                counts[key] = (counts[key] || 0) + 1;
                return counts;
            }, {});
            // Determine the most requested song if any song has more than 1 request
            const mostRequestedSongEntry = Object.entries(songCounts)
                .sort((a, b) => b[1] - a[1])
                .find(([, count]) => count > 1);
            return (
                <Box>
                    {/* Summary Statistics */}
                    <Box sx={{display: 'flex', justifyContent: 'flex-start', gap: '32px', marginBottom: '16px'}}>
                        <Typography>
                            Total Requests: <strong>{totalRequests}</strong>
                        </Typography>
                        {mostRequestedSongEntry && (
                            <Typography>
                                Most Requested Song:{" "}
                                <strong>{`${mostRequestedSongEntry[0]} (${mostRequestedSongEntry[1]} requests)`}</strong>
                            </Typography>
                        )}
                    </Box>
                    {/* Song Requests Table */}
                    <Grid container spacing={1} sx={{textAlign: 'center', fontWeight: 'bold', marginBottom: '8px'}}>
                        <Grid item xs={3}><h3>Username</h3></Grid>
                        <Grid item xs={3}><h3>Song Title</h3></Grid>
                        <Grid item xs={3}><h3>Artist</h3></Grid>
                        <Grid item xs={3}><h3>Type</h3></Grid>
                    </Grid>
                    {data.map((request, index) => (
                        <Grid container spacing={1} key={index} sx={{textAlign: 'center', padding: '4px 0', '&:nth-of-type(odd)': {backgroundColor: 'rgba(0, 0, 0, 0.05)'}}}>
                            <Grid item xs={3}>{request.username}</Grid>
                            <Grid item xs={3}>{request.songTitle}</Grid>
                            <Grid item xs={3}>{request.artist}</Grid>
                            <Grid item xs={3}>{request.type === 'twitchreward' ? 'Channel Ponts' : request.type === 'conversation' ? 'Chat' : 'Command'}</Grid>
                        </Grid>
                    ))}
                </Box>
            );
        },
    },    
    subscriptions: {
        label: 'Subscriptions',
        chartTypes: ['line', 'table'],
        intervalEnabled: true,
        render: (data, lineColor, interval, chartType) => {
            if (chartType === 'table') {
                return (
                    <Grid container spacing={1} sx={{textAlign: 'left', marginBottom: '16px', paddingLeft: '16px'}}>
                        <Grid item xs={4}><h3>Username</h3></Grid>
                        <Grid item xs={4}><h3>Tier</h3></Grid>
                        <Grid item xs={4}><h3>Time</h3></Grid>
                        {data.map((sub, index) => (
                            <Grid container spacing={1} key={index} sx={{textAlign: 'left', padding: '4px 8px', '&:nth-of-type(odd)': { backgroundColor: 'rgba(0, 0, 0, 0.1)' }}}>
                                <Grid item xs={4}>{sub.username}</Grid>
                                <Grid item xs={4}>{`Tier ${sub.tier / 1000}`}</Grid>
                                <Grid item xs={4}>
                                    {new Date(sub.timestamp).toLocaleTimeString('en-GB', {
                                        hour: '2-digit',
                                        minute: '2-digit',
                                    })}
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>
                );
            }
            const groupedData = groupByInterval(data, interval, (item) => item.timestamp, () => 1, 'sum');
            const maxY = Math.max(...groupedData.map((entry) => entry.count)) + 1;
            const getSubscriptionsData = () => {
                return {
                    labels: groupedData.map((entry) =>
                        new Date(entry.timestamp).toLocaleTimeString('en-GB', {
                            hour: '2-digit',
                            minute: '2-digit'
                        })
                    ),
                    datasets: [
                        {
                            label: 'Subscriptions Over Time',
                            data: groupedData.map((entry) => entry.count),
                            fill: false,
                            borderColor: lineColor,
                            tension: 0.4
                        }
                    ]
                };
            };
            return (
                <Chart type="line" data={getSubscriptionsData()} options={defaultChartOptions(lineColor, 'Number of Subscriptions', maxY)}
                    style={{ height: '100%', width: '100%' }} />
            );
        },
    },
    chatActivity: {
        label: 'Chat Activity',
        chartTypes: ['line', 'bar'],
        intervalEnabled: true,
        render: (data, lineColor, interval, chartType) => {
            if (!data || data.length === 0) {
                return <Typography variant="body1">No chat activity data available.</Typography>;
            }
            // Summary statistics
            const totalMessages = data.length;
            const messageCounts = data.reduce((counts, message) => {
                counts[message.username] = (counts[message.username] || 0) + 1;
                return counts;
            }, {});
            const mostActiveUser = Object.entries(messageCounts).sort((a, b) => b[1] - a[1])[0];
            // Group messages into intervals
            const groupedData = groupByInterval(data, interval, (item) => item.timestamp, () => 1, 'sum');
            // Chart Data
            const barChartData = {
                labels: Object.keys(messageCounts),
                datasets: [
                    {
                        label: 'Messages Per User',
                        data: Object.values(messageCounts),
                        backgroundColor: lineColor,
                        borderColor: lineColor,
                        borderWidth: 1
                    }
                ]
            };
            const lineChartData = {
                labels: groupedData.map((entry) =>
                    new Date(entry.timestamp).toLocaleTimeString('en-GB', {
                        hour: '2-digit',
                        minute: '2-digit'
                    })
                ),
                datasets: [
                    {
                        label: 'Messages Over Time',
                        data: groupedData.map((entry) => entry.count),
                        fill: false,
                        borderColor: lineColor,
                        tension: 0.4
                    }
                ]
            };
    
            return (
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '32px', marginBottom: '16px' }}>
                        <Typography>Total Messages: <strong>{totalMessages}</strong></Typography>
                        <Typography>
                            Most Active User: <strong>{mostActiveUser ? `${mostActiveUser[0]} (${mostActiveUser[1]} messages)` : 'N/A'}</strong>
                        </Typography>
                    </Box>
                    <Chart type={chartType} data={chartType === 'bar' ? barChartData : lineChartData} style={{height: '50vh', width: '100%'}}
                        options={defaultChartOptions(lineColor, 'Messages', Math.max(...groupedData.map((entry) => entry.count)) + 1, 'User')} />
                </Box>
            );
        },
    },
    commandStats: {
        label: 'Command Usage',
        chartTypes: ['bar'],
        intervalEnabled: false,
        render: (data, lineColor) => {
            if (!data || Object.keys(data).length === 0) {
                return <Typography variant="body1">No command usage data available.</Typography>;
            }
            // Summary statistics
            const totalCommands = Object.keys(data).length;
            const totalUsages = Object.values(data).reduce((sum, command) => sum + command.usageCount, 0);
            const mostUsedCommand = Object.entries(data).sort((a, b) => b[1].usageCount - a[1].usageCount)[0];
            const barChartData = {
                labels: Object.keys(data),
                datasets: [
                    {
                        label: 'Command Usage Count',
                        data: Object.values(data).map((command) => command.usageCount),
                        backgroundColor: lineColor,
                        borderColor: lineColor,
                        borderWidth: 1
                    }
                ]
            };

            return (
                <Box>
                    <Box sx={{ display: 'flex', justifyContent: 'flex-start', gap: '32px', marginBottom: '16px' }}>
                        <Typography>Total Commands: <strong>{totalCommands}</strong></Typography>
                        <Typography>Total Usages: <strong>{totalUsages}</strong></Typography>
                        <Typography>
                            Most Used Command: <strong>{mostUsedCommand ? `${mostUsedCommand[0]} (${mostUsedCommand[1].usageCount} uses)` : 'N/A'}</strong>
                        </Typography>
                    </Box>
                    <Chart type="bar" data={barChartData} style={{ height: '50vh', width: '100%' }}
                        options={defaultChartOptions(lineColor, 'Usage Count', Math.max(...Object.values(data).map((cmd) => cmd.usageCount)) + 1)} />
                </Box>
            );
        },
    },
    userStats: {
        label: 'User Stats',
        chartTypes: ['table'],
        intervalEnabled: false,
        render: (data, lineColor) => {
            if (!data || Object.keys(data).length === 0) {
                return <Typography variant="body1">No user statistics available.</Typography>;
            }
            // Calculate scores and sort users
            const userScores = Object.entries(data).map(([username, metrics]) => {
                const score =
                    metrics.messageCount +
                    metrics.commandCount +
                    metrics.emoteUsage * 0.5 +
                    metrics.songRequestCount * 2;
                return {username, ...metrics, score};
            }).sort((a, b) => b.score - a.score);
            return (
                <Box>
                    <Box sx={{display: 'flex', justifyContent: 'flex-start', gap: '32px', marginBottom: '16px'}}>
                        <Typography>Total Users: <strong>{userScores.length}</strong></Typography>
                        <Typography>
                            Most Active User: <strong>{userScores[0]?.username} ({userScores[0]?.score} points)</strong>
                        </Typography>
                    </Box>
                    <Grid container spacing={1} sx={{textAlign: 'center', fontWeight: 'bold', marginBottom: '8px'}}>
                        <Grid item xs={2}><h3>Username</h3></Grid>
                        <Grid item xs={2}><h3>Messages</h3></Grid>
                        <Grid item xs={2}><h3>Commands</h3></Grid>
                        <Grid item xs={2}><h3>Emotes</h3></Grid>
                        <Grid item xs={2}><h3>Song Requests</h3></Grid>
                        <Grid item xs={2}><h3>Score</h3></Grid>
                    </Grid>
                    {userScores.map((user, index) => (
                        <Grid container spacing={1} key={index} sx={{textAlign: 'center', padding: '4px 0', '&:nth-of-type(odd)': {backgroundColor: 'rgba(0, 0, 0, 0.05)'}}}>
                            <Grid item xs={2}>{user.username}</Grid>
                            <Grid item xs={2}>{user.messageCount}</Grid>
                            <Grid item xs={2}>{user.commandCount}</Grid>
                            <Grid item xs={2}>{user.emoteUsage}</Grid>
                            <Grid item xs={2}>{user.songRequestCount}</Grid>
                            <Grid item xs={2}>{user.score.toFixed(1)}</Grid>
                        </Grid>
                    ))}
                </Box>
            );
        },
    },
    activityMetrics: {
        label: 'Activity Metrics',
        chartTypes: ['line'],
        intervalEnabled: true,
        render: (data, lineColor, interval) => {
            if (!data) {
                return <Typography variant="body1">No activity metrics data available.</Typography>;
            }
    
            // Group data for each metric
            const groupedAdBreaks = groupByInterval(
                data.totalAdBreaks || [],
                interval,
                (timestamp) => timestamp,
                () => 1,
                'sum'
            );
            const groupedMessages = groupByInterval(
                data.totalMessages || [],
                interval,
                (timestamp) => timestamp,
                () => 1,
                'sum'
            );
            const groupedChatters = groupByInterval(
                data.uniqueChatters || [],
                interval,
                (timestamp) => timestamp,
                () => 1,
                'sum'
            );
            const groupedSubscriptions = groupByInterval(
                data.totalSubscriptions || [],
                interval,
                (timestamp) => timestamp,
                () => 1,
                'sum'
            );
            const groupedCommands = groupByInterval(
                data.totalCommands || [],
                interval,
                (timestamp) => timestamp,
                () => 1,
                'sum'
            );
            const groupedRewardRedemptions = groupByInterval(
                data.rewardRedemptions || [],
                interval,
                (timestamp) => timestamp,
                () => 1,
                'sum'
            );
    
            // Generate a complete set of timestamps
            const allTimestamps = Array.from(
                new Set([
                    ...groupedAdBreaks.map((entry) => entry.timestamp),
                    ...groupedMessages.map((entry) => entry.timestamp),
                    ...groupedChatters.map((entry) => entry.timestamp),
                    ...groupedSubscriptions.map((entry) => entry.timestamp),
                    ...groupedCommands.map((entry) => entry.timestamp),
                    ...groupedRewardRedemptions.map((entry) => entry.timestamp),
                ])
            ).sort((a, b) => new Date(a) - new Date(b));
    
            // Fill missing timestamps with 0 counts
            const alignDataToTimestamps = (groupedData, allTimestamps) =>
                allTimestamps.map((timestamp) => {
                    const found = groupedData.find((entry) => entry.timestamp === timestamp);
                    return found ? found.count : 0;
                });
    
            const alignedAdBreaks = alignDataToTimestamps(groupedAdBreaks, allTimestamps);
            const alignedMessages = alignDataToTimestamps(groupedMessages, allTimestamps);
            const alignedChatters = alignDataToTimestamps(groupedChatters, allTimestamps);
            const alignedSubscriptions = alignDataToTimestamps(groupedSubscriptions, allTimestamps);
            const alignedCommands = alignDataToTimestamps(groupedCommands, allTimestamps);
            const alignedRewardRedemptions = alignDataToTimestamps(groupedRewardRedemptions, allTimestamps);
    
            // Chart Data
            const combinedData = {
                labels: allTimestamps.map((timestamp) =>
                    new Date(timestamp).toLocaleTimeString('en-GB', {
                        hour: '2-digit',
                        minute: '2-digit',
                    })
                ),
                datasets: [
                    {
                        label: 'Ad Breaks',
                        data: alignedAdBreaks,
                        fill: false,
                        borderColor: 'rgba(75, 192, 192, 1)',
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        tension: 0.4,
                    },
                    {
                        label: 'Messages',
                        data: alignedMessages,
                        fill: false,
                        borderColor: 'rgba(255, 99, 132, 1)',
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        tension: 0.4,
                    },
                    {
                        label: 'Unique Chatters',
                        data: alignedChatters,
                        fill: false,
                        borderColor: 'rgba(54, 162, 235, 1)',
                        backgroundColor: 'rgba(54, 162, 235, 0.2)',
                        tension: 0.4,
                    },
                    {
                        label: 'Subscriptions',
                        data: alignedSubscriptions,
                        fill: false,
                        borderColor: 'rgba(153, 102, 255, 1)',
                        backgroundColor: 'rgba(153, 102, 255, 0.2)',
                        tension: 0.4,
                    },
                    {
                        label: 'Commands',
                        data: alignedCommands,
                        fill: false,
                        borderColor: 'rgba(255, 206, 86, 1)',
                        backgroundColor: 'rgba(255, 206, 86, 0.2)',
                        tension: 0.4,
                    },
                    {
                        label: 'Reward Redemptions',
                        data: alignedRewardRedemptions,
                        fill: false,
                        borderColor: 'rgba(201, 203, 207, 1)',
                        backgroundColor: 'rgba(201, 203, 207, 0.2)',
                        tension: 0.4,
                    },
                ],
            };
    
            // Chart.js Options with Dynamic Scaling
            const dynamicChartOptions = {
                ...defaultChartOptions(lineColor, 'Activity Metrics Over Time', Math.max(...alignedAdBreaks, ...alignedMessages)),
                plugins: {
                    legend: {
                        display: true,
                        position: 'top',
                        labels: {
                            color: lineColor,
                        },
                        onClick: (event, legendItem, legend) => {
                            // Access the Chart.js instance from the legend plugin
                            const chartInstance = legend.chart; 
                            const datasetIndex = legendItem.datasetIndex;
                        
                            // Toggle the visibility of the dataset
                            const meta = chartInstance.getDatasetMeta(datasetIndex);
                            meta.hidden = meta.hidden === null ? !chartInstance.data.datasets[datasetIndex].hidden : null;
                        
                            // Update the chart to reflect the changes
                            chartInstance.update();
                        },
                    },
                },
                scales: {
                    ...defaultChartOptions(lineColor, '', 1).scales,
                    y: {
                        ...defaultChartOptions(lineColor, '', 1).scales.y,
                        suggestedMax: null, // Reset suggested max for dynamic scaling
                        afterDataLimits: (axis) => {
                            // Dynamically calculate the max value based on visible datasets
                            const visibleDatasets = axis.chart.data.datasets.filter((ds, i) =>
                                !axis.chart.getDatasetMeta(i).hidden
                            );
                            const maxValues = visibleDatasets.flatMap((ds) => ds.data);
                            axis.max = Math.ceil(Math.max(...maxValues));
                            axis.min = 0; // Ensure min is always 0
                        },
                    },
                },
            };
    
            return (
                <Chart
                    type="line"
                    data={combinedData}
                    options={dynamicChartOptions}
                    style={{ height: '50vh', width: '100%' }}
                />
            );
        }
    },
    combinedMetrics: {
        label: 'Engagement Insight',
        chartTypes: ['line'],
        intervalEnabled: true,
        render: (data, lineColor, interval) => {
            if (!data.viewerCounts || !data.chatActivity) {
                return <Typography variant="body1">Insufficient data to render combined metrics.</Typography>;
            }
            // Group data for each metric
            const groupedViewerCounts = groupByInterval(data.viewerCounts, interval, (item) => item.timestamp, (item) => item.count, 'average');
            const groupedChatActivity = groupByInterval(data.chatActivity, interval, (item) => item.timestamp, () => 1, 'sum');
    
            // Generate a complete set of timestamps
            const allTimestamps = Array.from(new Set([
                ...groupedViewerCounts.map((entry) => entry.timestamp),
                ...groupedChatActivity.map((entry) => entry.timestamp),
            ])).sort((a, b) => new Date(a) - new Date(b));
    
            // Fill missing timestamps with 0 counts
            const alignDataToTimestamps = (groupedData, allTimestamps) =>
                allTimestamps.map((timestamp) => {
                    const found = groupedData.find((entry) => entry.timestamp === timestamp);
                    return found ? found.count : 0;
                });
    
            // Align all datasets to the complete set of timestamps
            const alignedViewerCounts = alignDataToTimestamps(groupedViewerCounts, allTimestamps);
            const alignedChatActivity = alignDataToTimestamps(groupedChatActivity, allTimestamps);
    
            // Chart Data
            const combinedData = {
                labels: allTimestamps.map((timestamp) =>
                    new Date(timestamp).toLocaleTimeString('en-GB', {
                        hour: '2-digit',
                        minute: '2-digit'
                    })
                ),
                datasets: [
                    {
                        label: 'Viewer Counts',
                        data: alignedViewerCounts,
                        fill: false,
                        borderColor: 'rgba(75, 192, 192, 1)',
                        backgroundColor: 'rgba(75, 192, 192, 0.2)',
                        tension: 0.4
                    },
                    {
                        label: 'Chat Activity',
                        data: alignedChatActivity,
                        fill: false,
                        borderColor: 'rgba(255, 99, 132, 1)',
                        backgroundColor: 'rgba(255, 99, 132, 0.2)',
                        tension: 0.4
                    }
                ]
            };
    
            // Dynamic Y-axis adjustment options
            const dynamicChartOptions = {
                ...defaultChartOptions(lineColor, 'Metrics Over Time', Math.max(...alignedViewerCounts, ...alignedChatActivity)),
                plugins: {
                    legend: {
                        display: true,
                        position: 'top',
                        labels: {
                            color: lineColor,
                        },
                        onClick: (event, legendItem, legend) => {
                            const chartInstance = legend.chart; // Access Chart.js instance
                            const datasetIndex = legendItem.datasetIndex;
                            const meta = chartInstance.getDatasetMeta(datasetIndex);
                            meta.hidden = meta.hidden === null ? !chartInstance.data.datasets[datasetIndex].hidden : null;
                            chartInstance.update();
                        }
                    },
                },
                scales: {
                    ...defaultChartOptions(lineColor, '', 1).scales,
                    y: {
                        ...defaultChartOptions(lineColor, '', 1).scales.y,
                        suggestedMax: null, // Reset suggested max for dynamic scaling
                        afterDataLimits: (axis) => {
                            // Dynamically calculate the max value based on visible datasets
                            const visibleDatasets = axis.chart.data.datasets.filter((ds, i) =>
                                !axis.chart.getDatasetMeta(i).hidden
                            );
                            const maxValues = visibleDatasets.flatMap((ds) => ds.data);
                            axis.max = Math.ceil(Math.max(...maxValues));
                            axis.min = 0; // Ensure min is always 0
                        }
                    }
                }
            };
    
            return (
                <Chart
                    type="line"
                    data={combinedData}
                    options={dynamicChartOptions}
                    style={{ height: '50vh', width: '100%' }}
                />
            );
        }
    },
    streamTitle: {
        label: 'Stream Summary',
        chartTypes: ['table'],
        intervalEnabled: false,
        render: (data, lineColor, selectedInterval, chartType, streamData) => {
            // Calculate stream duration
            const startedAt = new Date(streamData['startedAt']);
            const endedAt = new Date(streamData['endedAt']);
            const durationMs = endedAt - startedAt;
    
            const formatDuration = (ms) => {
                const totalSeconds = Math.floor(ms / 1000);
                const hours = Math.floor(totalSeconds / 3600);
                const minutes = Math.floor((totalSeconds % 3600) / 60);
                const seconds = totalSeconds % 60;
                return `${hours}h ${minutes}m ${seconds}s`;
            };
    
            const duration = durationMs > 0 ? formatDuration(durationMs) : 'N/A';
    
            // Calculate average viewer count
            const viewerCounts = streamData['viewerCounts'] || [];
            const averageViewerCount = viewerCounts.length > 0
                ? Math.round(viewerCounts.reduce((sum, entry) => sum + entry.count, 0) / viewerCounts.length)
                : 'N/A';

            // Format total ad time
            const formatAdTime = (seconds) => {
                const minutes = Math.floor(seconds / 60);
                const remainingSeconds = seconds % 60;
                return `${minutes}m ${remainingSeconds}s`;
            };

            const totalAdTimeFormatted = formatAdTime(streamData['totalAdTime'] || 0);
    
            // Render stream tags
            const streamTags = streamData['streamTags'] || [];
            const renderTags = () => (
                streamTags.length > 0 ? (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '8px', marginTop: '8px' }}>
                        {streamTags.map((tag, index) => (
                            <Box key={index} sx={{backgroundColor: 'rgba(0, 0, 0, 0.1)', padding: '4px 8px', borderRadius: '16px',
                                    fontSize: '0.875rem', fontWeight: 'bold', color: 'var(--complementary-color)'}}>{tag}</Box>
                        ))}
                    </Box>
                ) : (
                    <Typography variant="body2" sx={{ color: 'rgba(0, 0, 0, 0.6)' }}>
                        No tags used during this stream.
                    </Typography>
                )
            );
    
            return (
                <Grid container spacing={2}>
                    {/* Stream Title */}
                    <Grid item xs={12}>
                        <h2>{`${streamData['streamTitle']}`}</h2>
                    </Grid>
    
                    {/* General Info */}
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ padding: '16px', backgroundColor: 'rgba(235, 160, 200, 0.18)', borderRadius: '8px' }}>
                            <h3 style={{marginTop: '0px'}}>General Info</h3>
                            <Typography>{`Started at: ${startedAt.toLocaleDateString('en-GB', { hour: '2-digit', minute: '2-digit' })}`}</Typography>
                            <Typography>{`Ended at: ${endedAt.toLocaleDateString('en-GB', { hour: '2-digit', minute: '2-digit' })}`}</Typography>
                            <Typography>{`Duration: ${duration}`}</Typography>
                        </Box>
                    </Grid>
    
                    {/* Engagement Metrics */}
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ padding: '16px', backgroundColor: 'rgba(255, 223, 186, 0.3)', borderRadius: '8px' }}>
                            <h3 style={{marginTop: '0px'}}>Engagement</h3>
                            <Typography>{`Messages total: ${streamData['totalMessageCount']}`}</Typography>
                            <Typography>{`Commands used: ${streamData['totalCommandCount']}`}</Typography>
                            <Typography>{`Unique chatters: ${streamData['uniqueChatters'].length}`}</Typography>
                        </Box>
                    </Grid>
    
                    {/* Viewer Stats */}
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ padding: '16px', backgroundColor: 'rgba(173, 216, 230, 0.3)', borderRadius: '8px' }}>
                           <h3 style={{marginTop: '0px'}}>Viewer Stats</h3>
                            <Typography>{`Peak viewer count: ${streamData['peakViewerCount']}`}</Typography>
                            <Typography>{`Average viewer count: ${averageViewerCount}`}</Typography>
                        </Box>
                    </Grid>
    
                    {/* Ad Stats */}
                    <Grid item xs={12} sm={6}>
                        <Box sx={{ padding: '16px', backgroundColor: 'rgba(255, 182, 193, 0.3)', borderRadius: '8px' }}>
                            <h3 style={{marginTop: '0px'}}>Ad Performance</h3>
                            <Typography>{`Ad breaks: ${streamData['totalAdBreaks']}`}</Typography>
                            <Typography>{`Ad time: ${totalAdTimeFormatted}`}</Typography>
                        </Box>
                    </Grid>
    
                    {/* Stream Tags */}
                    <Grid item xs={12}>
                        <Box sx={{ padding: '16px', backgroundColor: 'rgba(204, 255, 204, 0.3)', borderRadius: '8px' }}>
                            <h3 style={{marginTop: '0px'}}>Stream Tags</h3>
                            {renderTags()}
                        </Box>
                    </Grid>
                </Grid>
            );
        }
    }     
};

// Main component
const SelectedStream = ({ streamData, onBack, colorScheme }) => {
    const defaultStat = localStorage.getItem('selectedStat') || 'activityMetrics';
    const defaultInterval = parseInt(localStorage.getItem('selectedInterval'), 10) || 900;
    const [selectedStat, setSelectedStat] = useState(defaultStat);
    const [selectedInterval, setSelectedInterval] = useState(defaultInterval);
    const [chartType, setChartType] = useState('line');
    const [lineColor, setLineColor] = useState('#FFFFFF');
    const intervalOptions = [
        { label: '1 Minute', value: 60 },
        { label: '5 Minutes', value: 300 },
        { label: '10 Minutes', value: 600 },
        { label: '15 Minutes', value: 900 },
        { label: '30 Minutes', value: 1800 },
        { label: '60 Minutes', value: 3600 }
    ];

    useEffect(() => {
        const rootStyle = getComputedStyle(document.documentElement);
        const complementaryColor = rootStyle.getPropertyValue('--complementary-color') || '#FFFFFF';
        setLineColor(complementaryColor.trim());
    }, []);

    const handleStatChange = (statKey) => {
        setSelectedStat(statKey);
        localStorage.setItem('selectedStat', statKey);
        const config = statConfig[statKey];
        if (config && config.chartTypes.includes('table')) setChartType('table');
        else if (config && config.chartTypes.includes('line')) setChartType('line');
    };
    const handleIntervalChange = (intervalValue) => {
        setSelectedInterval(intervalValue);
        localStorage.setItem('selectedInterval', intervalValue);
    };
    const toolBarStart = () => {
        return (
            <React.Fragment>
                <Dropdown value={selectedStat} options={Object.keys(statConfig).sort().map((key) => ({
                        label: statConfig[key].label,
                        value: key
                    }))} onChange={(e) => handleStatChange(e.value)} placeholder="Select a Statistic"
                    style={{ width: '400px', marginRight: '16px' }} />
                {currentStatConfig?.intervalEnabled && (
                    <Dropdown value={selectedInterval} options={intervalOptions} onChange={(e) => handleIntervalChange(e.value)} 
                        placeholder="Select Interpolation Interval" style={{ width: '300px', marginRight: '16px' }} />
                )}
                {currentStatConfig?.chartTypes.length > 1 && (
                    <Dropdown value={chartType} options={currentStatConfig.chartTypes.map((type) => ({
                            label: type === 'line' ? 'Line Chart' : type === 'bar' ? 'Bar Chart' : 'Table', value: type}))}
                        onChange={(e) => setChartType(e.value)} placeholder="Select Chart Type" style={{ width: '200px' }} />
                )}
            </React.Fragment>
        );
    };
    const toolBarEnd = () => {
        return (
            <React.Fragment>
                <Button label="Back to Streams" icon="pi pi-arrow-left" onClick={onBack} severity="info" />
            </React.Fragment>
        );
    };

    const currentStatConfig = selectedStat ? statConfig[selectedStat] : null;

    return (
        <Box sx={{ width: '100%' }}>
            <Toolbar start={toolBarStart()} end={toolBarEnd()} style={{ marginBottom: '16px' }} />
            {selectedStat && currentStatConfig ? (
                <Box sx={{padding: '16px', backgroundColor: colorScheme.background50, borderRadius: '8px', boxShadow: 2,
                        height: '60vh', maxHeight: '60vh', overflowY: 'auto'}}>
                    {currentStatConfig.render(streamData[selectedStat], lineColor, selectedInterval, chartType, streamData)}
                </Box>
            ) : (
                <Typography variant="body1" sx={{ textAlign: 'center', marginTop: '16px' }}>
                    Please select a statistic to view.
                </Typography>
            )}
        </Box>
    );
};

export default SelectedStream;
