import { Grid } from '@mui/material';
import { InputNumber } from 'primereact/inputnumber';
import { Slider } from 'primereact/slider';
import { Checkbox } from 'primereact/checkbox';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { SelectButton } from 'primereact/selectbutton';
import React, { useState } from 'react';
import useIsMobile from '../../../utils/useIsMobile';
import './hangman.css';

const HangmanSettings = ({openDialogComponent = () => {}, setIsBlur = () => {}, backgroundColor = '#5D3B9E', userSettings = {}, update = async () => {}, 
refresh = async () => {}, setMainViewHeader = () => {}}) => {
    const [lives, setLives] = useState(userSettings?.games?.hangman?.lives || 6);
    const [guessWindow, setGuessWindow] = useState(userSettings?.games?.hangman?.guessWindow || 15);
    const [showGuessedLetters, setShowGuessedLetters] = useState(userSettings?.games?.hangman?.showGuessedLetters || false);
    const [incorrectWordPenalty, setIncorrectWordPenalty] = useState(userSettings?.games?.hangman?.incorrectWordPenalty || 15);
    const [timeBetweenGames, setTimeBetweenGames] = useState(userSettings?.games?.hangman?.timeBetweenGames || 15);
    const [enableWordGuessing, setEnableWordGuessing] = useState(userSettings?.games?.hangman?.enableWordGuessing || true);
    const [enableHints, setEnableHints] = useState(userSettings?.games?.hangman?.enableHints || false);
    const [hintAtLives, setHintAtLives] = useState(userSettings?.games?.hangman?.hintAtLives || 1);
    const [customWordList, setCustomWordList] = useState(userSettings?.games?.hangman?.customWordList || []);
    const [wordListType, setWordListType] = useState(userSettings?.games?.hangman?.wordListType || 'Automatic');
    const [newWord, setNewWord] = useState('');
    const isMobile = useIsMobile();
    const colorScheme = {
        background75: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.75)`,
        background50: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.5)`,
        background25: `rgba(${parseInt(backgroundColor.slice(1, 3), 16)}, ${parseInt(backgroundColor.slice(3, 5), 16)}, ${parseInt(backgroundColor.slice(5, 7), 16)}, 0.25)`
    };
    const styles = {
        hideScrollbar: {
            '&::-webkit-scrollbar': {
                display: 'none' // Hide vertical scrollbar
            },
            '-ms-overflow-style': 'none', // For Internet Explorer and Edge
            'scrollbar-width': 'none' // For Firefox
        },
        slider: {
        },
        container: {
            maxHeight: '80vh', 
            overflowY: 'auto', 
            padding: !isMobile && '16px', 
            paddingTop: '0px', 
            paddingRight: isMobile ? '20px' : '32px'
        },
        customWordListContainer: {
            marginTop: '16px',
            maxHeight: '500px', 
            overflowY: 'auto'
        },
        selectedButton: {
        },
        unselectedButton: {
        }
    };

    const updateSetting = (key, value) => {
        update({games: {...userSettings.games, hangman: {...userSettings.games.hangman, [key]: value}}});
    };

    const addWordToCustomList = () => {
        if (newWord.trim()) {
            const updatedList = [...customWordList, newWord.trim()];
            setCustomWordList(updatedList);
            updateSetting('customWordList', updatedList);
            setNewWord('');
        }
    };

    const updateWordInCustomList = (index, newWord) => {
        const updatedList = [...customWordList];
        updatedList[index] = newWord;
        setCustomWordList(updatedList);
        updateSetting('customWordList', updatedList);
    };

    const removeWordFromCustomList = (word) => {
        const updatedList = customWordList.filter(w => w !== word);
        setCustomWordList(updatedList);
        updateSetting('customWordList', updatedList);
    };

    return (
        <React.Fragment>
            <Grid container sx={styles.container}>
                <Grid item xs={3}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <h2>Game Settings</h2>
                        </Grid>
                        <Grid item xs={12}>
                            <h3>Number of lives</h3>
                            <InputNumber decrementButtonClassName='p-button-danger' incrementButtonClassName='p-button-success' incrementButtonIcon='pi pi-plus' 
                                decrementButtonIcon='pi pi-minus' style={{width: '100%', background: 'var(--background-color)', borderRadius: '6px'}} value={lives} 
                                onValueChange={(e) => updateSetting('lives', e.value)} showButtons buttonLayout='horizontal' step={1} min={1} max={10} />
                        </Grid>
                        <Grid item xs={12}>
                            <Checkbox id='hmShowGuessedLettersCheck' checked={showGuessedLetters} onChange={(e) => {
                                setShowGuessedLetters(e.checked);
                                updateSetting('showGuessedLetters', e.checked);
                                }} />
                            <label style={{marginLeft: '16px'}} htmlFor='hmShowGuessedLettersCheck'>Show guessed letters</label>
                        </Grid>
                        <Grid item xs={12}>
                            <Checkbox id='hmEnableWordGuessingCheck' checked={enableWordGuessing} onChange={(e) => {
                                setEnableWordGuessing(e.checked);
                                updateSetting('enableWordGuessing', e.checked);
                            }} />
                            <label style={{marginLeft: '16px'}} htmlFor='hmEnableWordGuessingCheck'>Enable word guessing</label>
                        </Grid>
                        <Grid item xs={12}>
                        <h3>Hint system</h3>
                            <Checkbox id='hmEnableHintsCheck' checked={enableHints} onChange={(e) => {
                                setEnableHints(e.checked);
                                updateSetting('enableHints', e.checked);
                            }} />
                            <label style={{marginLeft: '16px'}} htmlFor='hmEnableHintsCheck'>Enable hints</label>
                            {enableHints && (
                                <div style={{marginTop: '16px'}}>
                                    <p>{`Hint will be shown at ${hintAtLives} lives remaining`}</p>
                                    <InputNumber decrementButtonClassName='p-button-danger' incrementButtonClassName='p-button-success' showButtons 
                                        incrementButtonIcon='pi pi-plus' decrementButtonIcon='pi pi-minus' buttonLayout='horizontal' 
                                        style={{width: '100%', background: 'var(--background-color)', borderRadius: '6px'}} value={hintAtLives} 
                                        onValueChange={(e) => {
                                            setHintAtLives(e.value);
                                            updateSetting('hintAtLives', e.value);
                                        }} min={1} max={lives} />
                                </div>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1} />
                <Grid xs={4}>
                    <Grid container spacing={4}>
                        <Grid item xs={12}>
                            <h2>Timing Settings</h2>
                        </Grid>
                        <Grid item xs={12}>
                            <h3>{`Guess window: ${guessWindow} seconds`}</h3>
                            <Slider style={styles.slider} value={guessWindow} min={0} max={120} step={5} onChange={(e) => setGuessWindow(e.value)} 
                                onSlideEnd={(e) => updateSetting('guessWindow', e.value)} />
                        </Grid>
                        <Grid item xs={12}>
                            <h3>{`Penalty for incorrect word guess: ${incorrectWordPenalty} seconds`}</h3>
                            <Slider style={styles.slider} value={incorrectWordPenalty} min={0} max={60} step={5} onChange={(e) => setIncorrectWordPenalty(e.value)} 
                                onSlideEnd={(e) => updateSetting('incorrectWordPenalty', e.value)} />
                        </Grid>
                        <Grid item xs={12}>
                            <h3>{`Minimum time between games: ${timeBetweenGames} seconds`}</h3>
                            <Slider style={styles.slider} value={timeBetweenGames} min={0} max={60} step={5} onChange={(e) => setTimeBetweenGames(e.value)} 
                                onSlideEnd={(e) => updateSetting('timeBetweenGames', e.value)} />
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={1} />
                <Grid xs={3}>
                    <h2>Word list settings</h2>
                    <SelectButton value={wordListType} options={[{label: 'Automatic', value: 'Automatic'}, {label: 'Custom', value: 'Custom'}]}
                        allowEmpty={false} style={{width: '100%'}} onChange={(e) => {
                            setWordListType(e.value);
                            updateSetting('wordListType', e.value);
                        }} itemTemplate={(option) => (
                            <span style={wordListType === option.value ? styles.selectedButton : styles.unselectedButton}>
                                {option.label}
                            </span>
                        )}  />
                    {wordListType === 'Custom' ? (
                        <React.Fragment>
                            <h3>Custom Word List</h3>
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                <InputText value={newWord} onChange={(e) => setNewWord(e.target.value)} placeholder='Enter a new word' style={{marginRight: '8px', flex: 1, background: 'var(--background-color)'}} />
                                <Button style={{color: 'var(--complementary-color)'}} severity='success' icon='pi pi-plus' onClick={addWordToCustomList} />
                            </div>
                            <div style={{...styles.customWordListContainer, ...styles.hideScrollbar}}>
                                {customWordList.map((word, index) => (
                                    <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                                        <InputText value={word} onChange={(e) => updateWordInCustomList(index, e.target.value)} style={{marginRight: '8px', flex: 1, background: 'var(--background-color)'}} />
                                        <Button severity='danger' icon='pi pi-minus' onClick={() => removeWordFromCustomList(word)} />
                                    </div>
                                ))}
                            </div>
                        </React.Fragment>
                    ) : (
                        <React.Fragment>
                            <h3>A word related to the game being played on stream will be chosen by the bot.</h3>
                        </React.Fragment>
                    )}
                </Grid>
            </Grid>
        </React.Fragment>
    );
};

export default HangmanSettings;